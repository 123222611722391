import React from "react";

import PageHeader from "../../common/Header";
import Footer from "../../common/Footer";

import SteuerberaterContent from "../../components/Steuerberater";

class LandingPage extends React.Component {
  constructor() {
    super();
    this.state = {
      fixedHeader: false,
      padding: 0
    };
  }
  activeStickyHeader = (value, height) => {
    this.setState({
      fixedHeader: value,
      padding: height
    });
  };
  detectFooterHeight = height => {
    this.setState({
      footerHeight: height
    });
  };

  _renderContent = () => {
    const { pathname } = this.props.location;

    switch (pathname) {
      case "/":
        return <SteuerberaterContent />;
        break;
    }
  };

  render() {
    const paddingValue = this.state.fixedHeader ? this.state.padding : "0";
    return (
      <div
        className="wrapper-container"
        style={{
          paddingTop: paddingValue,
          paddingBottom: this.state.footerHeight
        }}
      >
        <PageHeader activeStickyHeader={this.activeStickyHeader} />
        {this._renderContent()}
        <Footer detectFooterHeight={this.detectFooterHeight} />
      </div>
    );
  }
}

export default LandingPage;
