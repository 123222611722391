import React from "react";
import SliderForm from "../../../common/Form";
import "./style.scss";

import { benifits } from "../../../constants/index";

const Banner = (props) => {
  const benifitItems = benifits.map((elem, index) => {
    return <BenifitItem key={index} item={elem} />;
  });
  return (
    <div className="banner-block">
      <div className="banner-main-content">
        <h2 className="banner-title">
          <span className="accent-text">
            {" "}
            Steuerberater finden:{" "}
          </span>
          Wir finden den besten regionalen Experten für Ihr Anliegen
        </h2>
      
        <div className="form-block">
          <SliderForm userStartFillingForm={props.userStartFillingForm}/>
        </div>
      </div>
      <div className="benifits">
        <ul className="benifits-block">{benifitItems}</ul>
      </div>
    </div>
  );
};

const BenifitItem = props => (
  <li>
    <div className="content">
      <img className="icon" src={props.item.icon} alt="icon" />
      <span>{props.item.title}</span>
    </div>
  </li>
);

export default Banner;
