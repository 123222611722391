import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

class TopLinks extends React.Component {
  constructor() {
    super();
    this.state = {
      showNobMenu: false
    };
  }
  handleShowMenu = () => {
    this.setState({
      showNobMenu: !this.state.showNobMenu
    });
  };
  render() {
    const navItems = this.props.data.map((elem, index) => {
      return (
        <NavigationItem elem={elem} key={index} action={this.handleShowMenu} />
      );
    });
    const showMenu = this.state.showNobMenu ? "show" : "";
    return (
      <div className={"navigation " + showMenu}>
        <button className="menu-button" onClick={this.handleShowMenu} />
        <ul className="main-nav" style={{ top: this.props.headerHeight }}>
          {navItems}
        </ul>
      </div>
    );
  }
}

const NavigationItem = props => (
  <li onClick={props.action}>
    <Link to={props.elem.path}>{props.elem.name}</Link>
  </li>
);

export default TopLinks;
