import React from "react";
import PageHeader from "../../common/Header";
import Footer from "../../common/Footer";
//import parse library
import ReactHtmlParser from 'react-html-parser';

class SimplePage extends React.Component {
  constructor() {
    super();
    this.state = {
      fixedHeader: false,
      footerHeight: 0,
      padding: 0
    };
  }
  activeStickyHeader = (value, height) => {
    this.setState({
      fixedHeader: value,
      padding: height
    });
  };
  detectFooterHeight = height => {
    this.setState({
      footerHeight: height
    });
    console.log(height);
  };
  render() {
    const paddingValue = this.state.fixedHeader ? this.state.padding : "0";
    return (
      <div
        className="wrapper-container"
        style={{
          paddingTop: paddingValue,
          paddingBottom: this.state.footerHeight
        }}
      >
        <PageHeader activeStickyHeader={this.activeStickyHeader} />
        <section className="text-section">
          <h1>{this.props.data.title}</h1>
          {/* Render html via the parser */}
          <p>{ReactHtmlParser(this.props.data.text)}</p>
        </section>
        <Footer detectFooterHeight={this.detectFooterHeight} />
      </div>
    );
  }
}

export default SimplePage;
