import React from "react";
import "./style.scss";

import BlockTitle from "../../../common/Title/BlockTitle";
import CheckIcon from "../../../images/icons/check.png";
import Banner from "../../../images/attention-block-banner.jpg";

import { AttentionPoints as Data } from "../../../constants/index";

const AttentionPoints = () => {
  const Items = Data.map((elem, index) => {
    return <AttentionItem key={index} elem={elem} />;
  });
  return (
    <section className="attemtion-points-block">
      <BlockTitle content={"Einfach die besten Steuerberater für Ihre Anliegen"} />
      <div className="content">
        <ul className="point-list">{Items}</ul>
        <div>
          <img src={Banner} alt="banner" />
        </div>
      </div>
    </section>
  );
};

const AttentionItem = props => {
  if (props.elem.checked) {
    return (
      <li>
        <span className="icon">
          <img src={CheckIcon} alt="icon" />
        </span>
        <span>{props.elem.content}</span>
      </li>
    );
  } else {
    return (
      <li>
        <span className="icon" />
        <span>{props.elem.content}</span>
      </li>
    );
  }
};

export default AttentionPoints;
