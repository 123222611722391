import React from "react";
import "./style.scss";

import BlockTitle from "../../../common/Title/BlockTitle";
import { Reviews as ReviewsData } from "../../../constants/index";

import StarEmpty from "../../../images/icons/star-empty.svg";
import FullStar from "../../../images/icons/start-full.svg";
import Banner from "../../../images/img6.png";

class Reviews extends React.Component {
  constructor() {
    super();
    this.state = {
      mobileStep: 1
    };
  }
  prevSlide = () => {
    if (this.state.mobileStep > 1) {
      this.setState({
        mobileStep: this.state.mobileStep - 1
      });
    } else {
      return false;
    }
  };
  nextSlide = () => {
    if (this.state.mobileStep < ReviewsData.length) {
      this.setState({
        mobileStep: this.state.mobileStep + 1
      });
    } else {
      return false;
    }
  };
  render() {
    const ReviewItems = ReviewsData.map((item, index) => {
      return (
        <ReviewItem
          key={index}
          elem={item}
          step={this.state.mobileStep}
          index={index + 1}
        />
      );
    });
    return (
      <section className="reviews-block">
        <div className="content">
          <BlockTitle content={"Wir wollen zufriedene Kunden"} />
          <ul className="reviews-list">{ReviewItems}</ul>
          <div className="control-btns">
            <button
              className={this.state.mobileStep === 1 ? "prev disabled" : "prev"}
              onClick={this.prevSlide}
            >
              <ArrowSvg />
            </button>
            <button
              className={
                this.state.mobileStep === ReviewsData.length
                  ? "next disabled"
                  : "next"
              }
              onClick={this.nextSlide}
            >
              <ArrowSvg />
            </button>
          </div>
        </div>
        <div className="banner">
          <img src={Banner} alt="banner" />
        </div>
      </section>
    );
  }
}

const ArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.469"
    height="16.37"
    viewBox="0 0 28.469 16.37"
  >
    <defs />
    <path
      id="arrow_right"
      data-name="arrow right"
      d="M835.881,1814.82a1.225,1.225,0,0,1-.878-0.38,1.322,1.322,0,0,1,0-1.82l5.786-5.99-5.786-6a1.31,1.31,0,0,1,0-1.81,1.2,1.2,0,0,1,1.755,0l6.663,6.9a1.32,1.32,0,0,1,0,1.82l-6.663,6.9a1.223,1.223,0,0,1-.877.38h0Zm-19.312-6.9a1.286,1.286,0,0,1,0-2.57l24.164-.01a1.286,1.286,0,0,1,0,2.57l-24.164.01h0Z"
      transform="translate(-815.312 -1798.44)"
    />
  </svg>
);

const ReviewItem = props => {
  const stars = [];
  for (let i = 0; i < props.elem.rate; i++) {
    stars.push(<img src={FullStar} alt="star" key={i} />);
  }
  let mobClass;
  if (props.index === props.step) {
    mobClass = "active";
  } else {
    if (props.index < props.step) {
      mobClass = "prev";
    }
  }
  return (
    <li className={mobClass}>
      <header>
        <img className="avatar" src={props.elem.avatar} alt="avatar" />
        <span>{props.elem.name}</span>
        <div className="stars">
          <span className="empty">
            <img src={StarEmpty} alt="star" />
            <img src={StarEmpty} alt="star" />
            <img src={StarEmpty} alt="star" />
            <img src={StarEmpty} alt="star" />
            <img src={StarEmpty} alt="star" />
          </span>
          <span className="full">{stars}</span>
        </div>
      </header>
      <p className="review-text">{props.elem.review}</p>
    </li>
  );
};

export default Reviews;
