import React from "react";
import "./style.scss";
import BlockTitle from "../../../common/Title/BlockTitle";

const InfoBlock = () => (
  <section className="info-block">
    <BlockTitle content={"Wir finden Ihren passenden Steuerberater"} />
    <div className="content">
      <p>
Steuern und Steuererklärungen sind nicht nur für Unternehmen eine Herausforderung, sondern zunehmend auch für Privatpersonen. Jeder muss sich, früher oder später, umfassend mit diesem Thema auseinandersetzen.
<br />
<br />
Die Erstellung der eigenen Steuererklärung benötigt jedoch eine Menge Zeit und Geduld. Sollte Ihnen die Zeit oder die nötige Expertise fehlen, dann sind wir der Ansprechpartner für Sie. 
      </p>
      <p>
Wir vermitteln Ihnen den passenden Steuerberater in ihrer Umgebung. So erhalten sie aus einem Pool hunderter Steuerberater verschiedenster Fachgebiete, den Experten, der auch wirklich ihre individuellen Bedürfnisse erfüllt. Ihre Anfragen sind 100% kostenfrei und unverbindlich.
   
Lassen Sie die Steuerexperten Deutschlands auf Sie zukommen. Nie wieder Pauschalen oder Abrechnungen vergessen. Wir helfen Ihnen den passenden Experten zu finden. Schnell, einfach und kompetent.      
	  </p>
    </div>
  </section>
);

export default InfoBlock;
