import React from "react";

const Option = props => {
  const isActive = props.defaultValue === props.elem.name ? "active" : " ";
  return (
    <li>
      <button
        type="button"
        className={"option " + isActive}
        onClick={props.handleOptionCheck}
        value={props.elem.name}
        name={props.groupName}
      >
        <img className="icon" src={props.elem.icon} alt="icon" />
        <p>{props.elem.name}</p>
      </button>
    </li>
  );
};

export default Option;
