import React from "react";

import Banner from "./Banner";
import AttentionPoints from "./AttentionPoints";
import InfoLinks from "./InfoLinks";
import InfoBlock from "./InfoBlock";
import Faq from "./FAQ";
import Reviews from "./Reviews";

class SteuerberaterContent extends React.Component {
  constructor() {
    super();
    this.state = {
      isUserChooseStep: false
    };
  }
  userStartFillingForm = () => {
    this.setState({
      isUserChooseStep: true
    });
  }

  render() {
    const isUserChooseStep = this.state.isUserChooseStep
    return (
      <div>
        <Banner userStartFillingForm={this.userStartFillingForm} />
        { !isUserChooseStep &&
        <>
          <AttentionPoints />
          <InfoLinks />
          <InfoBlock />
          <Faq />
          <Reviews />
        </>
        }
      </div>
    );
  }
};

export default SteuerberaterContent;
