import React from "react";
import "./style.scss";

import FbIcon from "../../images/icons/fb.svg";
import TwIcon from "../../images/icons/tw.svg";
import FooterLinks from "./../Navigation/FooterLinks";
// import PromoMessage from "./PromoMessage";

import { FooterNavigationLinks, FooterRightLinks } from "../../constants/index";

class Footer extends React.Component {
  componentDidMount() {
    this.initeFooterHeight();
    window.addEventListener("resize", this.initeFooterHeight);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.initeFooterHeight);
  }
  initeFooterHeight = () => {
    setTimeout(this.detectFooterHeight, 350);
  };
  detectFooterHeight = () => {
    const { clientHeight } = this.refs.footer;
    this.props.detectFooterHeight(clientHeight);
  };
  openInNewTab = e => {
    let newPageUrl = e.target.name;
    let win = window.open(newPageUrl, "_blank");
    win.focus();
  };
  render() {
    return (
      <footer className="footer" ref="footer">
        <div className="footer-navigation-block">
          <FooterLinks className={"left-links"} data={FooterNavigationLinks} />
          <FooterLinks className={"right-links"} data={FooterRightLinks} />
        </div>
        <p className="copyrights">
          &copy; 2019 WirFindenDenBesten.de</p>
        <div className="clearfix">
          {/* <PromoMessage
            title={"aus STADT haben in den letzten7 Tagen nach THEMA gesucht"}
            count={724}
          /> */}
        </div>
        <div className="bottom-links">
          <h1 className="logo">WirFindenDenBesten.de</h1>
          <div>
            <button onClick={this.openInNewTab}>
              <img
                src={FbIcon}
                alt="icon"
                name={"https://www.facebook.com/WirfindendenBesten.de/?modal=admin_todo_tour"}
              />
            </button>
            <button onClick={this.openInNewTab}>
              <img
                src={TwIcon}
                alt="icon"
                name={"https://twitter.com/WirfdenBesten"}
              />
            </button>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
