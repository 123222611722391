import React from "react";

const ControlButtons = props => (
  <div className="control-btns">
    <button
      className={
        props.step < 2 || props.step > props.length
          ? "btn-primary prev hide"
          : "btn-primary prev"
      }
      onClick={props.handlePrevStep}
    >
      zurück
    </button>
    <button
      className={
        props.step >= props.length + 1
          ? "btn-primary next hide"
          : "next btn-primary"
      }
      onClick={props.handleNextStep}
      disabled={props.userAnswer}
    >
      weiter
    </button>
  </div>
);

export default ControlButtons;
