import React from "react";
import { Link } from "react-router-dom";

import BlockTitle from "../../../common/Title/BlockTitle";
import { LinksData } from "../../../constants/index";
import "./style.scss";
const PageHeader = () => {
  const linksItem = LinksData.map((item, index) => {
    return (
      <Link className="info-links-item" to={item.path} key={index}>
            </Link>
    );
  });
  return (
    <section className="info-links-block">
       
    </section>
  );
};  

export default PageHeader;
