export const templateBuilder = formData => {
    var mailBody = '';
	mailBody += 'Lead generiert von /steuerberater/' + "\n\n";    
	mailBody += 'Für wen wird der Steuerberater benötigt?' + "\n" + formData['Option 1'] + "\n\n";
    mailBody += 'Welche Dienstleistung benötigen Sie?' + "\n" + formData['Option 2'] + "\n\n";
    mailBody += 'Werden sie bereits von einem Steuerberater betreut?' + "\n" + formData['Option 3'] + "\n\n";
    mailBody += 'Beschreiben Sie Ihr Anliegen kurz (Formular)' + "\n" + formData['details'] + "\n";
    mailBody += 'Kontaktdaten:' + "\n" + 'Name:' + formData.contacts.firstName + ' ' + formData.contacts.lastName + "\n";
    mailBody += 'E-Mail: ' + formData.contacts.email + "\n";
    mailBody += 'Tel: ' + formData.contacts.phone + "\n";
    mailBody += 'Plz: ' + formData.contacts.plz + "\n";
    return mailBody
}