import React from "react";

class DetailsSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      blockHeight: 0
    };
  }
  componentDidMount() {
    this.initeSlideHeight();
    window.addEventListener("resize", this.initeSlideHeight);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.initeSlideHeight);
  }
  initeSlideHeight = () => {
    setTimeout(this.slideHeightDetect, 350);
  };
  slideHeightDetect = () => {
    const { clientHeight } = this.refs.slide;
    this.setState(
      {
        blockHeight: clientHeight
      },
      this.props.detectSlideHeight("slide" + this.props.position, clientHeight)
    );
  };
  handleTextareaChange = e => {
    this.setState({
      message: e.target.value
    });
  };
  handleDataSender = e => {
    e.preventDefault();
    this.props.saveData("details", this.state.message);
  };
  render() {
    return (
      <div
        className={"slide callback-form " + this.props.slideClass}
        ref="slide"
      >
        <h3 className="title">Bitte beschreiben Sie Ihr Anliegen genauer, damit wir den bestmöglichen Steuerberater für Sie ausfindig machen können!</h3>
        <div className="form-group">
          <textarea
            type="text"
            name="name"
            placeholder="Ihre Beschreibung..."
            value={this.state.name}
            onChange={this.handleTextareaChange}
          />
        </div>
        <button className="btn-primary next" onClick={this.handleDataSender}>
          weiter
        </button>
      </div>
    );
  }
}

export default DetailsSlide;
