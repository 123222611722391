import React from "react";
import "./style.scss";
import BlockTitle from "../../../common/Title/BlockTitle";
import { Faq as FaqData } from "../../../constants/index";
import ArrowIcon from "../../../images/icons/icon-drop-down.svg";

const Faq = () => {
  const FaqItemsLeft = FaqData[0].questions.map((item, index) => {
    return <Item elem={item} key={index} />;
  });
  const FaqItemsRight = FaqData[1].questions.map((item, index) => {
    return <Item elem={item} key={index} />;
  });
  return (
    <section className="fac-block">
      <BlockTitle content={"Häufig gestellte Fragen rund um die Experten und Leistungen"} />
      <div className="content">
        <div className="faq-block-items">
          <ul className="faq-list clearfix">{FaqItemsLeft}</ul>
          <ul className="faq-list clearfix">{FaqItemsRight}</ul>
        </div>
      </div>
    </section>
  );
};

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
  }
  handleClick = () => {
    this.setState({
      show: !this.state.show
    });
  };
  render() {
    return (
      <li>
        <h4 className="tite" onClick={this.handleClick}>
          {this.props.elem.question}
          <span className={this.state.show ? "icon open" : "icon"}>
            <img src={ArrowIcon} alt="icon-down" />
          </span>
        </h4>

        <div className={this.state.show ? "answer-block show" : "answer-block"}>
          <p className="answer">{this.props.elem.answer}</p>
        </div>
      </li>
    );
  }
}

export default Faq;
