import React from "react";

class LastInfoSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockHeight: 0
    };
  }
  componentDidMount() {
    const { clientHeight } = this.refs.slide;
    this.setState(
      {
        blockHeight: clientHeight
      },
      this.props.detectSlideHeight(
        "slide" + (this.props.length + 3),
        clientHeight
      )
    );
  }
  render() {
    const showSlide = this.props.step === this.props.length + 3 ? "show" : "";
    return (
      <div className={"slide callback-form last " + showSlide} ref="slide">
        <h3 className="title">
          Vielen Dank für die Bewerbung, wir werden uns in Kürze mit Ihnen in
          Verbindung setzen
        </h3>
      </div>
    );
  }
}

export default LastInfoSlide;
