import React from "react";
import TrustIcon from "../../images/logo_trust.png";
import { Link } from "react-router-dom";

import TopLinks from "../Navigation/TopLinks";
import { NavigationLinks } from "../../constants/index";

import Logo from "../../images/wfdb-logo.png";
import "./style.scss";

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContacts: false,
      fixedHeader: false,
      headerHeight: ""
    };
  }
  componentDidMount() {
    this.detectHeaderHeight();
    window.addEventListener("resize", this.detectHeaderHeight);
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.detectHeaderHeight);
    window.removeEventListener("scroll", this.handleScroll);
  }

  header = React.createRef();

  detectHeaderHeight = () => {
    let headerHeightVal = this.refs.header.clientHeight;
    this.setState({
      headerHeight: headerHeightVal
    });
  };
  handleScroll = () => {
    let scrollY = window.scrollY;
    let headerHeightVal = this.refs.header.clientHeight;

    if (scrollY > headerHeightVal) {
      this.setState(
        {
          fixedHeader: true,
          headerHeight: headerHeightVal
        },
        () =>
          this.props.activeStickyHeader(this.state.fixedHeader, headerHeightVal)
      );
    } else {
      this.setState(
        {
          fixedHeader: false
        },
        () =>
          this.props.activeStickyHeader(this.state.fixedHeader, headerHeightVal)
      );
    }
  };
  handleToggleContacts = () => {
    this.setState({
      showContacts: !this.state.showContacts
    });
  };
  render() {
    const fixed = this.state.fixedHeader ? "fixed" : "";
    return (
      <div className={"header-block " + fixed}>
        <header className={"main-header " + fixed} ref="header">
          <div className="top-header">
            <div className="logo">
              <Link to={"/"}>
                {" "}
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <div className="right-sidebar">
              <div className="trust-block">
                <img src={TrustIcon} alt="trust" />
              </div>
              <div className="contacts">
                <div className="icon" onClick={this.handleToggleContacts}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24.94"
                    height="24.156"
                    viewBox="0 0 24.94 24.156"
                  >
                    <defs />
                    <path
                      id="phone"
                      d="M1084.01,49.793a19.89,19.89,0,0,1-13.56-5.854l-0.07-.079h0c-3.98-3.889-6.15-8.774-6.04-13.487l0.01-.348,0.22-.272a14.9,14.9,0,0,1,1.04-1.142,13.17,13.17,0,0,1,4.55-2.873,2.02,2.02,0,0,1,.7-0.119,2.483,2.483,0,0,1,2.21,1.168c0.59,1.579,1.33,3.636,1.87,5.6a2.434,2.434,0,0,1-.8,2.375l-1.89,1.167a1.316,1.316,0,0,0,.25.63,24.53,24.53,0,0,0,5.48,5.312,1.123,1.123,0,0,0,.6.219l0.65-.888,0.61-.924a2.594,2.594,0,0,1,1.99-.813,1.867,1.867,0,0,1,.41.041,27.657,27.657,0,0,1,5.9,1.845,2.433,2.433,0,0,1,1,2.8,12.584,12.584,0,0,1-2.95,4.406,13.315,13.315,0,0,1-1.19,1.011l-0.28.212-0.71.012h0ZM1066.49,30.77a16.874,16.874,0,0,0,5.4,11.625l0.11,0.1a17.664,17.664,0,0,0,11.96,5.215c0.25-.2.49-0.413,0.71-0.628a10.548,10.548,0,0,0,2.47-3.687c-0.01-.008-0.05-0.135-0.1-0.237a25.71,25.71,0,0,0-5.26-1.62,2.322,2.322,0,0,0-.26.052l-1.08,1.649c-0.66,1.053-2.44,1.223-3.72.315a28.456,28.456,0,0,1-3.2-2.643,28.859,28.859,0,0,1-2.75-3.122,2.869,2.869,0,0,1-.59-2.276,2.024,2.024,0,0,1,.93-1.345l1.69-1.044a1.184,1.184,0,0,0,.05-0.288c-0.48-1.7-1.14-3.583-1.7-5.079a0.918,0.918,0,0,0-.29-0.06,11.048,11.048,0,0,0-3.73,2.381,8.887,8.887,0,0,0-.64.689h0Z"
                      transform="translate(-1064.34 -25.625)"
                    />
                  </svg>
                </div>
                <span
                  className={this.state.showContacts ? "number show" : "number"}
                >
                  <a href="tel:0211 - 158 477 64">0211 - 158 477 64</a>
                </span>
              </div>
            </div>
          </div>
        </header>
        <TopLinks
          data={NavigationLinks}
          headerHeight={this.state.headerHeight}
        />
      </div>
    );
  }
}

export default PageHeader;
