import React from "react";
import Option from "./Option";

class FormSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockName: "slide" + props.possition,
      blockHeight: 0
    };
  }
  componentDidMount() {
    const clientHeight = this.refs[this.state.blockName].clientHeight;
    this.setState(
      {
        blockHeight: clientHeight
      },
      this.props.detectSlideHeight(this.state.blockName, clientHeight)
    );
    this.initeSlideHeight();
    window.addEventListener("resize", this.initeSlideHeight);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.initeSlideHeight);
  }
  initeSlideHeight = () => {
    setTimeout(this.slideHeightDetect, 350);
  };
  slideHeightDetect = () => {
    const clientHeight = this.refs[this.state.blockName].clientHeight;
    this.setState(
      {
        blockHeight: clientHeight
      },
      this.props.detectSlideHeight(this.state.blockName, clientHeight)
    );
  };
  render() {
    const slideOptions = this.props.elem.options.map((elem, index) => {
      return (
        <Option
          key={index}
          elem={elem}
          handleOptionCheck={this.props.handleOptionCheck}
          groupName={this.props.elem.option}
          defaultValue={this.props.defaultValues[this.props.elem.option]}
        />
      );
    });

    let slideClass = " ";
    if (this.props.possition === this.props.step) {
      slideClass = "shown";
    } else {
      if (this.props.possition < this.props.step) {
        slideClass = "prev";
      } else {
        slideClass = "next";
      }
    }
    return (
      <div className={"slide " + slideClass} ref={this.state.blockName}>
        <h3 className="title">{this.props.elem.name}</h3>
        <div className="slide-options">{slideOptions}</div>
      </div>
    );
  }
}

export default FormSlide;
