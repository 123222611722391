import React from "react";
import { FormData } from "../../constants/index";
import { templateBuilder } from './modules';
import FormSlide from "./FormSlide";

import "./style.scss";

import CallBackSlide from "./CallBackSlide";
import ControlButtons from "./ControlButtons";
import DetailsSlide from "./DetailsSlide";
import LastInfoSlide from "./LastInfoSlide";

// Default form valuels
const defaultValues = {};

// Slider form
class Form extends React.Component {
  constructor() {
    super();
    this.state = {
      step: 1,
      formData: defaultValues
    };
  }
  handleOptionCheck = e => {
    e.preventDefault();
    this.props.userStartFillingForm();
    this.setState({
      step: this.state.step + 1,
      formData: {
        ...this.state.formData,
        [e.currentTarget.name]: e.currentTarget.value
      }
    });
  };
  detectSlideHeight = (name, value) => {
    this.setState({
      [name]: value
    });
  };
  handleNextStep = () => {
    this.props.userStartFillingForm();
    this.setState({
      step: this.state.step + 1
    });
  };
  handlePrevStep = () => {
    this.setState({
      step: this.state.step - 1
    });
  };
  sendData = () => {
    if (this.state.step === FormData.length + 3) {
      fetch("/sendmail", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          mode: 'no-cors'
        },
        body: JSON.stringify({ formData: templateBuilder(this.state.formData) })
      })
      .then(result => {
        console.log(result);
      });
    }
  };
  saveContactData = (type, value) => {
    const sendDataFunc = this.sendData;
    this.setState(
      {
        step: this.state.step + 1,
        formData: {
          ...this.state.formData,
          [type]: value
        }
      },
      sendDataFunc
    );
  };
  render() {
    const slides = FormData.map((item, index) => {
      return (
        <FormSlide
          key={index}
          elem={item}
          possition={index + 1}
          step={this.state.step}
          handleOptionCheck={this.handleOptionCheck}
          defaultValues={this.state.formData}
          detectSlideHeight={this.detectSlideHeight}
        />
      );
    });
    const slideClass = this.state.step === FormData.length + 2 ? "show" : "";
    const slideClassMessage =
      this.state.step === FormData.length + 1 ? "show" : "";

    const formHeight = this.state["slide" + this.state.step];
    return (
      <div className="slider-form-group">
        <div className="slider-form-block">
          <div className="promo-lable">
            <p className="persents">100%</p>
            <p>kostenlos</p>
          </div>
          <form className="slider-form " style={{ height: formHeight }}>
            {slides}
            <CallBackSlide
              slideClass={slideClass}
              position={FormData.length + 2}
              saveContactData={this.saveContactData}
              detectSlideHeight={this.detectSlideHeight}
            />
            <DetailsSlide
              slideClass={slideClassMessage}
              saveData={this.saveContactData}
              position={FormData.length + 1}
              detectSlideHeight={this.detectSlideHeight}
            />
            <LastInfoSlide
              step={this.state.step}
              length={FormData.length}
              detectSlideHeight={this.detectSlideHeight}
            />
          </form>
          <ControlButtons
            handleNextStep={this.handleNextStep}
            handlePrevStep={this.handlePrevStep}
            handleShow={this.handleShow}
            userAnswer={Object.keys(this.state.formData).length < this.state.step}
            step={this.state.step}
            length={FormData.length}
          />
        </div>
      </div>
    );
  }
}

export default Form;
