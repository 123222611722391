import React from "react";
import TrustIcon from "../../images/logo_trust.png";
//Import Google analytics
import ReactGA from 'react-ga';
ReactGA.initialize('UA-134876925-1');

const Erorrs = {
  name: "Der Name sollte mehr als 2 Symbole enthalten",
  email: "Ungültige E-Mail",
  plz: "PLZ sollte mehr als 5 Ziffern enthalten",
  phone: "Ungültiges Telefon"
};

const genderData = [
  {
    name: "Herr",
    value: "Herr"
  },
  {
    name: "Frau",
    value: "Frau"
  }
];

const privatPolicy = [
  {
    name:
      "Ja, ich stimme der Datenschutzerklärung und den AGB zu. (Widerruf jederzeit möglich)",
    value: "policy"
  }
];

class CallBackSlide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blockHeight: 0,
      firstName: "",
      lastName: "",
      email: "",
      postCode: "",
      gender: "",
      phone: "",
      policyValid: "",
      firstNameValid: "",
      lastNameValid: "",
      emailValid: "",
      postCodeValid: "",
      genderValid: "",
      phoneValid: "",
      showErrors: false
    };
  }
  componentDidMount() {
    this.initeSlideHeight();
    window.addEventListener("resize", this.initeSlideHeight);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.initeSlideHeight);
  }
  initeSlideHeight = () => {
    setTimeout(this.slideHeightDetect, 350);
  };
  slideHeightDetect = () => {
    const { clientHeight } = this.refs.slide;
    this.setState(
      {
        blockHeight: clientHeight
      },
      this.props.detectSlideHeight("slide" + this.props.position, clientHeight)
    );
  };
  saveFormData = (name, value, valid) => {
    this.initeSlideHeight();
    this.setState({
      [name]: value,
      [name + "Valid"]: valid
    });
  };
  onSunmit = e => {
    e.preventDefault();
    this.initeSlideHeight();
    if (
      this.state.firstNameValid === true &&
      this.state.lastNameValid === true &&
      this.state.emailValid === true &&
      this.state.genderValid === true &&
      this.state.policyValid === true &&
      this.state.postCodeValid === true
    ) {
      let value = {};
      value.firstName = this.state.firstName;
      value.lastName = this.state.lastName;
      value.phone = this.state.phone;
      value.email = this.state.email;
      value.gender = this.state.gender;
      value.plz = this.state.postCode;
      this.props.saveContactData("contacts", value);

      //Google send event
      ReactGA.event({
        category: 'UserForm ',
        action: 'User submit'
      })
    } else {
      this.setState({
        showErrors: true
      });
    }
  };
  render() {
    return (
      <div
        className={"slide callback-form " + this.props.slideClass}
        ref="slide"
      >
        <h3 className="title">
          Jetzt den besten Steuerberater finden
        </h3>
        <h5 className="title-sm">Wer möchte das Angebot erhalten?</h5>

        <div className="form-group">
          <div>
            <RadioBlock
              className={
                this.state.showErrors && !this.state.genderValid
                  ? "not-valid"
                  : ""
              }
              data={genderData}
              type={"radio"}
              groupName={"gender"}
              saveFormData={this.saveFormData}
              tabIndex={[1, 2]}
            />
          </div>
          <div className="info-mes">*Pflichtfeld</div>
        </div>
        <div className="column-block">
          <div className="form-group">
            <FormInput
              className={
                this.state.showErrors && !this.state.firstNameValid
                  ? "not-valid"
                  : ""
              }
              type={"text"}
              name={"firstName"}
              placeholder={"Vorname*"}
              action={this.saveFormData}
              error={Erorrs.name}
              tabIndex={2}
            />
          </div>
          <div className="form-group">
            <FormInput
              className={
                this.state.showErrors && !this.state.lastNameValid
                  ? "not-valid"
                  : ""
              }
              type={"text"}
              name={"lastName"}
              placeholder={"Nachname*"}
              action={this.saveFormData}
              error={Erorrs.name}
              tabIndex={3}
            />
          </div>
          <InputHint
            title={"Hinweise zum Ausfüllen:"}
            text={
              "Bitte achten Sie darauf, dass Vor- und Nachname nicht vertauscht sind. Der Vorname muss im ersten Feld stehen."
            }
          />
        </div>
        <div className="form-group">
          <FormInput
            className={
              this.state.showErrors && !this.state.emailValid ? "not-valid" : ""
            }
            type={"phone"}
            name={"phone"}
            placeholder={"Telefonnummer*"}
            action={this.saveFormData}
            error={Erorrs.phone}
            tabIndex={4}
          />
          <InputHint
            title={"Warum fragen wir das?"}
            text={
              "Wenn es Rückfragen zu Ihrer Anfrage gibt, sind diese am einfachsten telefonisch zu klären. Ihre Telefonnummer wird ausschließlich für Rückfragen zu dieser Anfrage genutzt. Eine automatische Weitergabe Ihrer Telefonnummer an die gefundenen Anbieter ist ausgeschlossen."
            }
          />
        </div>
        <div className="form-group">
          <FormInput
            className={
              this.state.showErrors && !this.state.emailValid ? "not-valid" : ""
            }
            type={"email"}
            name={"email"}
            placeholder={"E-Mail:*"}
            action={this.saveFormData}
            error={Erorrs.email}
            hideHints={this.hideHints}
            tabIndex={5}
          />
          <InputHint
            title={"Warum fragen wir das?"}
            text={
              "Sie erhalten bis zu 3 Empfehlungen für passende Anbieter per E-Mail. Eine automatische Weitergabe Ihrer E-Mail-Adresse an die gefundenen Anbieter ist ausgeschlossen."
            }
          />
        </div>
        <div className="form-group">
          <FormInput
            className={
              this.state.showErrors && !this.state.postCodeValid
                ? "not-valid"
                : ""
            }
            type={"number"}
            name={"postCode"}
            placeholder={"PLZ*"}
            action={this.saveFormData}
            error={Erorrs.plz}
            hideHints={this.hideHints}
            tabIndex={6}
          />
          <InputHint
            title={"Postleitzahl"}
            text={"Für die Suche nach passenden Immobilienmaklern"}
            show={this.state.hints}
          />
        </div>
        <div className="form-information">
          <div className="icon">
            <span>&#8505;</span>
          </div>
          Neues Datenschutzgesetz ab dem 25. Mai 2018: Wir haben die
          Anforderungen in Ihrem Interesse umgesetzt.
        </div>
        <div className="form-group">
          <RadioBlock
            className={
              this.state.showErrors && !this.state.policyValid
                ? "not-valid"
                : ""
            }
            data={privatPolicy}
            type={"checkbox"}
            groupName={"policy"}
            saveFormData={this.saveFormData}
            tabIndex={[[8]]}
          />
        </div>
        <button className="btn-submit" tabIndex="8" onClick={this.onSunmit}>
          <span className="text-md">Jetzt den besten Steuerberater finden</span>
          kostenlos &amp; unverbindlich
        </button>
        <div className="form-footer">
          <ul className="benifits-list">
            <li>100% kostenlos &amp; unverbindlich</li>
            <li>Keine Auftragspflicht</li>
            <li>Anbieterkontakt nur mit Ihrer Zustimmung</li>
          </ul>
          <div className="trust-icon">
            <img src={TrustIcon} alt="icon" />
          </div>
        </div>
      </div>
    );
  }
}

class FormInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      valid: Boolean,
      errorMessage: false,
      focus: false
    };
  }
  handleInputFocus = () => {
    this.setState({
      focus: true
    });
  };
  handleInputUnFocus = () => {
    if (this.state.value.length < 1) {
      this.setState({
        focus: false
      });
    }
  };
  handleChange = e => {
    const inputValue = e.target.value;
    let validation;

    this.setState({
      value: e.target.value
    });

    switch (this.props.name) {
      case "firstName":
        validation = inputValue.length >= 2 ? true : false;
        break;
      case "lastName":
        validation = inputValue.length >= 2 ? true : false;
        break;
      case "email":
        let regExp = /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/;
        validation = regExp.test(inputValue) && inputValue.length ? true : false;
        break;
      case "postCode":
        validation = inputValue.length === 5 ? true : false;
        break;
      case "phone":
        let regExpPhone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
        validation = regExpPhone.test(inputValue) && inputValue.length > 0 ? true : false;
        break;
      default:
        console.info("validation error");
    }

    this.props.action(this.props.name, e.target.value, validation);
  };
  render() {
    return (
      <div className={this.state.focus ? "active" : ""}>
        <input
          type={this.props.type}
          name={this.props.name}
          value={this.state.value}
          onChange={this.handleChange}
          onFocus={this.handleInputFocus}
          onBlur={this.handleInputUnFocus}
          className={this.props.className}
          tabIndex={this.props.tabIndex}
        />
        <span className="placeholder">{this.props.placeholder}</span>
        <div
          className={
            this.props.className === "not-valid" ? "error show" : "error"
          }
        >
          {this.props.error}
        </div>
      </div>
    );
  }
}

class RadioBlock extends React.Component {
  handleOptionCheck = e => {
    this.props.saveFormData(e.target.name, e.target.value, true);
  };
  render() {
    const itemsList = this.props.data.map((elem, index) => {
      const tab = this.props.tabIndex[index];
      return (
        <div key={index} className={"check-group " + this.props.className}>
          <input
            type={this.props.type}
            id={elem.value}
            value={elem.value}
            name={this.props.groupName}
            onChange={this.handleOptionCheck}
            tabIndex={tab}
          />
          <label tabIndex={tab} htmlFor={elem.value}>
            {elem.name}
          </label>
        </div>
      );
    });
    return <React.Fragment>{itemsList}</React.Fragment>;
  }
}

class InputHint extends React.Component {
  render() {
    return (
      <div className="hint-block">
        <div className="button-hint">?</div>
        <div className="info">
          <h6>{this.props.title}</h6>
          <p>{this.props.text}</p>
        </div>
      </div>
    );
  }
}

export default CallBackSlide;
