import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";

import LandingPage from "./LandingPage";
import SimplePage from "./SimplePage";

import { LegalPagesContent } from "../constants/index";

const App = () =>  (
  <BrowserRouter basename="/steuerberater">
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route
        path="/agb"
        render={() => <SimplePage data={LegalPagesContent.AGB} />}
      />
      <Route
        path="/datenschutz"
        render={() => <SimplePage data={LegalPagesContent.Datenschutz} />}
      />
      <Route
        path="/impressum"
        render={() => <SimplePage data={LegalPagesContent.Impressum} />}
      />
    </Switch>
</BrowserRouter>
);

export default App;
