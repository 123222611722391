import React from "react";
import { Link } from "react-router-dom";
import "./style.scss";

class FooterLinks extends React.Component {
  render() {
    const navItems = this.props.data.map((elem, index) => {
      return <NavigationItem elem={elem} key={index} />;
    });
    return (
      <div className={"footer-navigation " + this.props.className}>
        <ul className="nav" style={{ top: this.props.headerHeight }}>
          {navItems}
        </ul>
      </div>
    );
  }
}

const NavigationItem = props => (
  <li onClick={props.action}>
    <Link to={props.elem.path}>{props.elem.name}</Link>
  </li>
);

export default FooterLinks;
